<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <h1>非凸活动</h1>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-24.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1tFt2eqEA6/?spm_id_from=333.999.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：第四届中国Rust开发者大会</div>
                  <div>活动时间：2024.09.07-08</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：Rust 中文社区</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1tFt2eqEA6/?spm_id_from=333.999.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://www.bilibili.com/video/BV1Ab4zerEi4/?spm_id_from=333.999.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-22.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1zH4y1p7yb/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：Rust在金融领域的应用</div>
                  <div>活动时间：2024.03.24 20:00-21:00</div>
                  <div>分享人：赵海峰</div>
                  <div class="mb-4">主办方：Rust.cc</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1zH4y1p7yb/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-22.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-20.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1KQ4y187Sq/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：基于Rust的安全、有收益的通用交易算法</div>
                  <div>活动时间：2023.11.08 20:30-21:00</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">主办方：Rust.cc，TinTin</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1KQ4y187Sq/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-20.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-17.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1ow411B7Hn/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：算法交易基础和工程实践</div>
                  <div>活动时间：2023.10.22 20:00-21:00</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：中国人民大学量协</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1ow411B7Hn/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-17.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-15.jpg"
                    alt="开源中国"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1294y1a7UP/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：数据与前沿技术</div>
                  <div>活动时间：2023.09.23 13:30-17:30</div>
                  <div>分享人：赵海峰</div>
                  <div class="mb-4">主办方：开源中国 OSC源创会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1294y1a7UP/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-15.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-14.jpg"
                    alt="运筹offer"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1Hu4y1r7ah/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：职场会客厅——对话非凸科技首席架构师</div>
                  <div>活动时间：2023.09.17 19:00-20:00</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：运筹offer</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1Hu4y1r7ah/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-14.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-13.jpg"
                    alt="机器之心"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1tV411P79K/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：AI offer 最优解——2024届秋招线上宣讲会</div>
                  <div>活动时间：2023.09.12 19:00-21:00</div>
                  <div>分享人：艾洁</div>
                  <div class="mb-4">主办方：机器之心 Talent AI</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1tV411P79K/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-13.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-11.jpg"
                    alt="WAIC开源集市"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1WN411m7tq/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：2023世界人工智能大会·开源集市</div>
                  <div>活动时间：2023.07.06-08</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">主办方：国家发展和改革委员会等</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1vm4y1E7xv/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://mp.weixin.qq.com/s/Byi7VITdrdTRBAoP-nkeTA"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-10.jpg"
                    alt="Rust开发者大会"
                    class="w-100"
                  />
                  <a
                    href="https://mp.weixin.qq.com/s/GiV-9MrlzB-yHMW6boThwg"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：第三届中国Rust开发者大会</div>
                  <div>活动时间：2023.06.17-18</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：Rust中文社区</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV16o4y1P7RY/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355ag"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://www.bilibili.com/video/BV1cm4y1Y7uA/?spm_id_from=444.41.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-09.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://mp.weixin.qq.com/s/p_kP-z1K-blsnKT-blMitg"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：第五届全球金融科技创业大赛</div>
                  <div>活动时间：2023.03.23 13:30-18:00</div>
                  <div>分享人：王浚澎</div>
                  <div class="mb-4">指导单位：清华五道口</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://mp.weixin.qq.com/s/p_kP-z1K-blsnKT-blMitg"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://www.weiyangx.com/2022fintech"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-07.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://h5.xunzhuang.net/h5/U140105D6YY35AY93"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：e海通聚研讨会</div>
                  <div>活动时间：2023.01.12 15:00-17:00</div>
                  <div>分享人：王浚澎</div>
                  <div class="mb-4">主办方：海通证券&非凸科技</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/read/cv21216995?spm_id_from=333.999.0.0"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://h5.xunzhuang.net/h5/U140105D6YY35AY93"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
       <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-06.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV19W4y147KE/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>分享主题：Rust在算法交易中的实际应用与积极效应</div>
                  <div>分享时间：2022.11.06 9:00-17:00</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：中泰证券 XTP</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV19W4y147KE/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-06.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "LivestreamPoster",
};
</script>

<style scoped lang="scss">
.container-lg {
  border-radius: 2rem;
}
.icon {
  font-size: 4rem;
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/livestream-banner.jpg");
  background-position-x: 25%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.poster {
  width: 100%;
}
.video-aside-text {
  background-color: aliceblue;
  letter-spacing: 0.5px;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 2.5rem !important;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .video-aside-text {
    padding: 1.5rem !important;
  }
}
</style>
